import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { httpGet } from '../../services/http'
import './style.less'
import { CalendarOutlined, FundOutlined, SettingOutlined } from '@ant-design/icons'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Pagewrapper from '../../components/PageWrapper'

export default function PreGuidePage() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "main-pages.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    useEffect(() => {
        httpGet('/landing/article/pre-guide/read')
    }, [])

    return (
        <Pagewrapper>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0.11,1)',
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="main-pages"
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="ll33a.dk consists of 3 main pages"
                subTitle={
                    <>
                        <CalendarOutlined style={{ fontSize: '24px' }} /> Calendar Page{' '}
                        <FundOutlined style={{ fontSize: '24px' }} /> Graph Page{' '}
                        <SettingOutlined style={{ fontSize: '24px' }} /> Setings Page
                    </>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/">
                        <Button>back</Button>
                    </Link>,
                    <Link to="/onboarding/step1/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </Pagewrapper>
    )
}
